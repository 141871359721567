window.op =
  window.op ||
  ((...args) => {
    (window.op.q = window.op.q || []).push(args);
  });
window.op('init', {
  clientId: '735f700a-336f-49e2-a0b5-7e5566de68fa',
  trackScreenViews: true,
  trackOutgoingLinks: true,
  trackAttributes: true,
});
